import { Controller } from "stimulus"
import {
  Core,
  // FileInput,
  Informer,
  ProgressBar,
  XHRUpload,
  ThumbnailGenerator
} from 'uppy'
import '../src/css/modules/uppy.scss'

export default class extends Controller {
  static targets = [
    'fileInput',
    'hiddenField',
    'progress',
    'preview',
    'actions',
    'destroyField'
  ]

  connect() {
    this.uppy = Core({
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png'],
        maxNumberOfFiles: 1,
        maxFileSize: 5242880,
      }
    })
      .use(XHRUpload, {
        endpoint: '/upload', // path to the upload endpoint
        limit: 4
      })
      .use(ProgressBar, {
        target: this.progressTarget,
      })
      .use(Informer, {
        target: this.element,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        thumbnailType: 'image/jpeg',
        waitForThumbnailsBeforeUpload: false
      })

    // if (this.hasReplaceFieldTarget) {
    //   this.uppy.use(FileInput, {
    //     target: this.replaceFieldTarget,
    //     replaceTargetContent: true,
    //     pretty: true,
    //     locale: {
    //       strings: {
    //         chooseFiles: this.data.get('saved') === 'true' ? 'Change your photo' : 'Upload your photo'
    //       }
    //     }
    //   })
    // }

    this.customInput()

    uppy.on('thumbnail:generated', (file, preview) => {
      this.previewTarget.querySelector('img').src = preview
    })

    uppy.on('upload-success', (file, response) => {
      // retrieve uploaded file data
      const uploadedFileData = response.body['data']
      this.hiddenFieldTarget.value = JSON.stringify(uploadedFileData)
    })

  }

  customInput() {
    this.fileInputTarget.addEventListener('change', (event) => {
      const files = Array.from(event.target.files)

      files.forEach((file) => {
        try {
          this.uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file
          })
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            console.log('Restriction error:', err)
          } else {
            // handle other errors
            console.error(err)
          }
        }
      })
    })

    // it’s probably a good idea to clear the `<input>`
    // after the upload or when the file was removed
    // (see https://github.com/transloadit/uppy/issues/2640#issuecomment-731034781)
    this.uppy.on('file-removed', () => {
      this.fileInputTarget.value = null
    })

    this.uppy.on('complete', () => {
      this.fileInputTarget.value = null
    })

  }

}
