import { Controller } from "stimulus"
import WaveSurfer from 'wavesurfer.js'
import '../src/css/modules/wave.css'

export default class extends Controller {
  static targets = [ 'waveform', 'playIcon', 'pauseIcon' ]

  connect() {
    if (this.hasWaveformTarget) {
      this.wavesurfer = WaveSurfer.create({
        container: this.waveformTarget,
        height: 40,
        responsive: true,
        waveColor: '#414141',
        progressColor: '#3F99BB',
      })
      this.wavesurfer.load(this.waveformTarget.dataset.wavePath)
      // temp solution to redraw waveform
      // waveform can't be drawed on hidden element
      // better to use wavesurfer.drawBuffer() on tab
      setInterval(() => {
        // window.dispatchEvent(new Event('resize'))
        this.wavesurfer.drawBuffer()
      }, 3000)
    }
    // custom event emitted to pause other instances
    this.wavesurfer.on('play', () => {
      const eventPlay = new CustomEvent('wave:play', {detail: this.element})
      window.dispatchEvent(eventPlay)
    })

  }

  // action
  play() {
    this.wavesurfer.playPause()
    this.playIconTarget.classList.toggle('d-none')
    this.pauseIconTarget.classList.toggle('d-none')
  }

  // action
  toPause() {
    this.playIconTarget.classList.remove('d-none')
    this.pauseIconTarget.classList.add('d-none')
  }

  // action from custom event
  globalPause(event) {
    if (event.detail !== this.element) {
      this.wavesurfer.pause()
      this.toPause()
    }
  }

}
