import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ 'lastMessages', 'userchat' ]

  // alert('<%= _("You can chat only with #{@limit} voice talent at the same time, close a chat window in order to chat with another voice talent") %>');

  initialize() {
    this.lazyAllMessages()

    consumer.subscriptions.create({
      channel: "AllChatsChannel",
    }, {
      connected: this._cableConnected.bind(this),
      received: this._cableReceived.bind(this),
    })
  }

  _cableConnected() {
    console.log(`Connected to all chats`)
  }

  _cableReceived(data) {
    const targetMes = this.lastMessagesTarget.querySelector(`[data-uuid='${data.uuid}'`)
    const parser = new DOMParser()
    const full = parser.parseFromString(data.message, "text/html")
    const message = full.querySelector("[data-action~='click->all-chats#showChat']")
    const innerMessage = message.innerHTML
    const outerMessage = message.outerHTML
    if (targetMes) {
      targetMes.innerHTML = innerMessage
    } else {
      this.lastMessagesTarget.insertAdjacentHTML('afterbegin', outerMessage)
    }
    // TODO
    // this.scrollToBottom()
  }

  lazyAllMessages() {
    fetch('/user/messages')
    .then(response => response.json())
    .then(data => {
      document.body.insertAdjacentHTML('afterbegin', data.html)
    })
  }

  // action
  showChat(event) {
    fetch('/user/messages/open/' + event.currentTarget.dataset.uuid)
    .then(response => response.json())
    .then(data => {
      const parser = new DOMParser()
      const full = parser.parseFromString(data.html, "text/html")
      const chatWindow = full.querySelector("[data-controller~='chat']")

      // insert only non duplicate windows
      if (!this.uuids || !this.uuids.includes(chatWindow.dataset.chatUuid)) {
        // 15 + 300 + 15 (+ 15 + 300)
        chatWindow.style.right = `${330 + 315 * this.userchatTargets.length}px`
        document.body.prepend(chatWindow)
      }
    })
  }

  // action from custom event
  // moving other windows
  // TODO: prevent same order of windows
  moveChats() {
    for (let index = 0; index < this.userchatTargets.length; index++) {
      const element = this.userchatTargets[index]
      console.log(element)
      element.style.right = `${330 + 315 * index}px`
    }
  }

  get uuids() {
    let array = []
    for (let index = 0; index < this.userchatTargets.length; index++) {
      const element = this.userchatTargets[index];
      array.push(element.dataset.chatUuid)
    }
    return array
  }

}
