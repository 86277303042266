import { Controller } from "stimulus"
import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  XHRUpload,
} from 'uppy'
import { headers } from '../src/js/csrf'
import '../src/css/modules/uppy.scss'

export default class extends Controller {
  static targets = [
    'replaceField',
    'hiddenField',
    'preview',
    'languageField',
    'categoryField',
    'uuidField',
    'progress',
    'audioTemplate'
  ]

  connect() {
    const uppy = Core({
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        allowedFileTypes: ['audio/*', '.mp3', '.wav', '.aac', '.m4a'],
        maxNumberOfFiles: 1,
        maxFileSize: 10485760,
      }
    })
    .use(XHRUpload, {
      endpoint: '/upload', // path to the upload endpoint
      limit: 10
    })
    .use(ProgressBar, {
      target: this.progressTarget,
    })
    .use(Informer, {
      target: this.element,
    })

    if (this.hasReplaceFieldTarget) {
      uppy.use(FileInput, {
        target: this.replaceFieldTarget,
        replaceTargetContent: true,
        pretty: true,
        locale: {
          strings: {
            chooseFiles: 'Upload your audio sample'
          }
        }
      })
    }

    uppy.on('upload-success', (file, response) => {
      // retrieve uploaded file data
      const uploadedFileData = response.body['data']
      const path = response.body['url']
      this.attachData(uploadedFileData, path)
    })
  }

  attachData(uploadedFileData, path) {
    // take template for audio wave and append uploaded file
    this.replaceFieldTarget.classList.add('d-none')
    this.previewTarget.innerHTML = this.audioTemplateTarget.innerHTML.replace(/WavePath/g, path)
    // append data to hidden form field
    this.hiddenFieldTarget.value = JSON.stringify(uploadedFileData)
  }

  // action
  deleteSample(event) {
    const uuid = event.currentTarget.dataset.uppyUuid

    fetch(this.data.get('createPath') + '/' + uuid, {
      method: 'DELETE',
      headers: headers,
    }).then(res => {
      this.replaceFieldTarget.classList.remove('d-none')
      this.previewTarget.innerHTML = null
    }).catch(error => console.error("we got an error:", error))
  }

  // action
  changeSample(event) {
    event.preventDefault()
    alert('Temporary disabled')
  }

}
