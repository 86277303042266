import { Controller } from "stimulus"
import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  XHRUpload,
  ThumbnailGenerator
} from 'uppy'
import '../src/css/modules/uppy.scss'

export default class extends Controller {
  static targets = [
    'replaceField',
    'hiddenField',
    'progress',
    'preview',
    'actions',
    'destroyField'
  ]

  connect() {
    const uppy = Core({
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
        maxNumberOfFiles: 1,
        maxFileSize: 5242880,
      }
    })
    .use(XHRUpload, {
      endpoint: '/upload', // path to the upload endpoint
      limit: 4
    })
    .use(ProgressBar, {
      target: this.progressTarget,
    })
    .use(Informer, {
      target: this.element,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 200,
      thumbnailHeight: 200,
      thumbnailType: 'image/jpeg',
      waitForThumbnailsBeforeUpload: false
    })

    if (this.hasReplaceFieldTarget) {
      uppy.use(FileInput, {
        target: this.replaceFieldTarget,
        replaceTargetContent: true,
        pretty: true,
        locale: {
          strings: {
            chooseFiles: this.data.get('saved') === 'true' ? 'Change studio image' : 'Add studio image'
          }
        }
      })
    }

    uppy.on('thumbnail:generated', (file, preview) => {
      this.previewTarget.src = preview
    })

    uppy.on('upload-success', (file, response) => {
      // retrieve uploaded file data
      const uploadedFileData = response.body['data']
      this.hiddenFieldTarget.value = JSON.stringify(uploadedFileData)
    })

  }

  // action
  deleteStudioImage(event) {
    if (confirm('Are you sure?')) {
      this.destroyFieldTarget.value = 'true'
      this.previewTarget.style.opacity = '.3'
    }
  }

}
