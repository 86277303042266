import { Controller } from "stimulus"
import consumer from "../channels/consumer"
import { headers } from '../src/js/csrf'

export default class extends Controller {
  static targets = [ 'messages', 'input', 'userchat' ]

  connect() {
    // don't do on Chat with me button
    if (!this.element.classList.contains('chatme')) {
      this.uuid = this.data.get('uuid')
      // scroll down after opening
      this.scrollToBottom()
  
      consumer.subscriptions.create({
        channel: "ChatChannel",
        uuid: this.uuid
      }, {
        connected: this._cableConnected.bind(this),
        received: this._cableReceived.bind(this),
      })
    }
  }

  scrollToBottom() {
    const list = this.messagesTarget
    // scrollTop: amount of scroll user has done
    // scrollHeight: total container size
    list.scrollTop = list.scrollHeight
  }

  // action
  startChat(element) {
    console.log('startChat clicked')
    fetch('/user/messages/new?id=' + element.currentTarget.dataset.userId)
    .then(response => response.json())
    .then(data => {
      // document.body.insertAdjacentHTML('afterbegin', data.html)

      const parser = new DOMParser()
      const full = parser.parseFromString(data.html, "text/html")
      const chatWindow = full.querySelector("[data-controller~='chat']")

      // insert only non duplicate windows
      if (!this.uuids || !this.uuids.includes(chatWindow.dataset.chatUuid)) {
        // 15 + 300 + 15 (+ 15 + 300)
        chatWindow.style.right = `${330 + 315 * this.userchatTargets.length}px`
        document.body.insertAdjacentHTML('afterbegin', chatWindow.outerHTML)
      }
    })
  }

  _cableConnected() {
    console.log(`Connected to chat_channel:${this.uuid}`)
  }

  _cableReceived(data) {
    // prevent duplication
    // own message attached from fetch response, not websockets
    if (data.to_user_id != this.data.get('toUserId')) {
      this.messagesTarget.insertAdjacentHTML('beforeend', data.message)
      this.scrollToBottom()
    }
  }

  // action
  sendMessage() {
    const toUserId = this.data.get('toUserId')
    const content = this.inputTarget.value

    // only non empty content
    if (content.length !== 0) {
      fetch('/user/messages', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          message: {
            uuid: this.uuid,
            to_user_id: toUserId,
            content: content,
          }
        })
      }).then(response => {
        if (!response.ok) {
          response.json().then(json => { throw json }) // throwing to catch block
        }
        return response.json() // we only get here if there is no error
      }).then(res => {
        this.inputTarget.value = ''
        this.messagesTarget.insertAdjacentHTML('beforeend', res.html)
      }).catch(err => console.error('we got an error: ', err))
    }
  }

  // action
  // can send a message with [Enter]
  // or insert new line with [Cmd/Ctrl + Enter]
  handleInputKeys(event) {
    if (event.code == "Enter") {
      event.preventDefault()
      // event object destructuring
      const { altKey, ctrlKey, metaKey, shiftKey } = event
      const withModifier = altKey || shiftKey || (this.isMac ? metaKey : ctrlKey)
      if (withModifier) {
        this.handleLineBreak()
      } else {
        this.sendMessage()
      };
    }
  }

  handleLineBreak() {
    this.inputTarget.value = `${this.inputTarget.value}\n`
  }

  get isMac() {
    return navigator.platform.match(/Mac/) != null
  }

  get uuids() {
    let array = []
    for (let index = 0; index < this.userchatTargets.length; index++) {
      const element = this.userchatTargets[index];
      array.push(element.dataset.chatUuid)
    }
    return array
  }

}
