// here we will import all needed icons
import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faGlobe,
  faChevronDown,
  faChevronUp,
  faTimes,
  faPlay,
  faPause,
} from '@fortawesome/free-solid-svg-icons'

import {
  faComments,
} from '@fortawesome/free-regular-svg-icons'

import { faYoutube } from '@fortawesome/free-brands-svg-icons'

// We are only using the user-astronaut icon
library.add(
  faGlobe,
  faComments,
  faYoutube,
  faChevronDown,
  faChevronUp,
  faTimes,
  faPlay,
  faPause,
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
