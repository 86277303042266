import { Controller } from "stimulus"
import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  XHRUpload,
} from 'uppy'
import '../src/css/modules/uppy.scss'

export default class extends Controller {
  static targets = [
    'replaceField',
    'hiddenField',
    'progress',
    'destroyField',
  ]

  connect() {
    const uppy = Core({
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        allowedFileTypes: ['.pdf'],
        maxNumberOfFiles: 1,
        maxFileSize: 3145728,
      }
    })
    .use(XHRUpload, {
      endpoint: '/upload', // path to the upload endpoint
      limit: 10
    })
    .use(ProgressBar, {
      target: this.progressTarget,
    })
    .use(Informer, {
      target: this.element,
    })

    if (this.hasReplaceFieldTarget) {
      uppy.use(FileInput, {
        target: this.replaceFieldTarget,
        replaceTargetContent: true,
        pretty: true,
        locale: {
          strings: {
            chooseFiles: this.data.get('saved') ? 'Change your resume' : 'Upload your resume'
          }
        }
      })
    }

    uppy.on('upload-success', (file, response) => {
      // retrieve uploaded file data
      const uploadedFileData = response.body['data']
      this.hiddenFieldTarget.value = JSON.stringify(uploadedFileData)
    })

  }

  // action
  deleteResume(event) {
    if (confirm('Are you sure?')) {
      this.destroyFieldTarget.value = 'true'
    }
  }

}
