import { Controller } from "stimulus"
import { setCookie } from '../src/js/cookie'

export default class extends Controller {

  // action
  toggleChat(event) {
    const list = event.currentTarget.closest('.userchat').querySelector('.userchat__collapsible')
    list.classList.toggle('userchat__collapsible_min')
    const up = event.currentTarget.querySelector('.fa-chevron-up')
    const down = event.currentTarget.querySelector('.fa-chevron-down')
    up.classList.toggle('d-none')
    down.classList.toggle('d-none')

    if (list.classList.contains('userchat__collapsible_min')) {
      setCookie('allChatOpen', '0')
    } else {
      setCookie('allChatOpen', '1')
    }

  }

  // action
  closeChat(event) {
    event.currentTarget.closest('.userchat').remove()
    const closeEvent = new CustomEvent('chat:close')
    window.dispatchEvent(closeEvent)
  }

}
